/**
 * Layouts -> Footer
 */

import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useSiteData from '@utils/hooks/useSiteData';
import { ReactComponent as Logo } from '@assets/images/logo/logo.svg';
import { Link } from 'gatsby';
import { ReactComponent as LinkedInIcon } from '@components/Icon/icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from '@components/Icon/icons/facebook.svg';
import { ReactComponent as InstragramIcon } from '@components/Icon/icons/instagram.svg';
import Container from '@components/Container';
import Button from '@components/Button';
import ContactInfo from '@components/ContactInfo';
import TextFormatting from '@components/TextFormatting';
import Icon from '@components/Icon';
import {
  root,
  bottom,
  bottom__container,
  bottom__start,
  bottom__end,
  bottom__end__text,
  bottom__start__text,
  bottom__nav,
  bottom__end__socials,
  end,
  nav,
  nav__heading,
  nav__list,
  nav__item,
  columns,
  inner,
  description,
  start,
  contactInfo,
  textBelowContact,
  logo as logoClass,
  top,
} from './Footer.module.css';

const ICONS = {
  instagram: InstragramIcon,
  linkedin: LinkedInIcon,
  facebook: FacebookIcon,
};

const Footer = ({ className }) => {
  const data = useSiteData();
  const copyRightText = useMemo(() => `© ${data.siteName} ${new Date().getFullYear()}`, []);

  return (
    <footer id="s-footer" className={classnames(root, className)}>
      <Container className={inner}>
        <div className={top}>
          <a className={logoClass} href="/" aria-label="Home">
            <Logo />
          </a>
        </div>
        <div className={columns}>
          <div className={start}>
            <TextFormatting className={description} content={data.footer.description} />
            <ContactInfo className={contactInfo} />
            {data.footer.textBelowContact ? (
              <div className={textBelowContact}>{data.footer.textBelowContact}</div>
            ) : null}
          </div>
          <div className={end}>
            {data.footer.menu.map((menu) => (
              <nav
                className={nav}
                key={menu.heading}
                aria-labelledby={`${menu.heading.toLowerCase()}-label`}
              >
                <h3 className={nav__heading} id={`${menu.heading.toLowerCase()}-label`}>
                  {menu.page?.uri ? (
                    <Link to={menu.page.uri}>{menu.page.title || menu.heading}</Link>
                  ) : (
                    menu.heading
                  )}
                </h3>
                <ul className={nav__list}>
                  {menu.menuItems.map((menuItem) => (
                    <li className={nav__item} key={menuItem.page.uri}>
                      <Link to={menuItem.page.uri}>{menuItem.label ?? menuItem.page.title}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            ))}
          </div>
        </div>
      </Container>
      <div className={bottom}>
        <Container className={bottom__container}>
          <div className={bottom__start}>
            <div className={bottom__start__text}>{copyRightText}</div>
            <nav className={bottom__nav}>
              <ul>
                <li>
                  <Link to="/cookie-policy">Cookie Policy</Link>
                </li>
                <li>
                  <Link to="/portal">Login</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className={bottom__end}>
            <h3 id="social-media-label" className={bottom__end__text}>
              {data.footer.socialText}
            </h3>
            <nav className={bottom__end__socials} aria-labelledby="social-media-label">
              <ul>
                {data.socials.map(
                  ({ link, label, platform }) =>
                    ICONS[platform] && (
                      <li key={link}>
                        <Button
                          element="a"
                          href={link}
                          target="_blank"
                          rel="noreferrer"
                          size="large"
                          appearance="icon-light"
                          title={label}
                        >
                          <Icon size="large" icon={ICONS[platform]} />
                        </Button>
                      </li>
                    ),
                )}
              </ul>
            </nav>
          </div>
        </Container>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default memo(Footer, () => true);
